.footer {
  max-width: 1232px;
  margin: 0 auto;
  padding: 50px 16px;
}
.footerContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
}
.copyright {
  opacity: 0.5;
  text-align: center;
  font-size: 14px;
}
.footerLabel {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 15px;
}
.footerLink,
.footerLink:hover {
  margin-bottom: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;
}

@media (min-width: 768px) {
  .footerContent {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .footerContent {
    flex-direction: row;
  }
}

:global(.fs-dark) {
  .footerLink,
  .footerLink:hover {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}
