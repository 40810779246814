.wrap {
  text-align: center;
  margin: 2rem auto;
  max-width: 1200px;
}
.subtitle {
  display: block;
  font-size: 24px;
  max-width: 500px;
}
.text {
  font-size: 18px;
}
.chart {
  background-color: #fff;
  padding: 16px;
  text-align: left;
  border-radius: 8px;
}
.featureImage {
  width: 128px;
  height: 128px;
  border-radius: 128px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  // filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  margin: 0 auto 20px;
}
.featureImageContent {
  width: 64px;
  height: 64px;
}
.featureText {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.container {
  max-width: 1232px;
  margin: 0 auto;
}
.section {
  padding: 0 16px;
}
.section1 {
  padding: 50px 0 50px;
  text-align: center;
}
.title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  & span {
    display: block;
    font-size: 40px;
    font-weight: normal;
    margin-top: 16px;
  }
}
.slide {
  padding: 0 10px;
}
@media (min-width: 400px) {
  .slide {
    padding: 0 20px;
  }
}
.dots li button {
  background: #1890ff !important;
  opacity: 0.4;
}
.dots :global(li.slick-active) button {
  background: #1890ff !important;
}

.img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  vertical-align: top;
}

:global(.fs-dark) {
  .featureImage {
    background-color: #333;
    // filter: none;
  }
  .img {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
