.button {
  box-sizing: border-box;
  height: 32px;
  padding: 8px 14px;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #dedede;
  min-width: 32px;
}
.button--v2 {
  padding: 0 !important;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  & .button__icon {
    height: inherit !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  & .button__soloIcon {
    height: 100%;
    min-width: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
  &.button--variant-outlined {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  &.button--color-white {
    background: rgba(255, 255, 255, 0.5);
    color: #000;
  }
  &.button--color-white:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }
}
.button--v2:hover {
  background-color: #f2f2f2;
}
.button--v2--text.button--v2--icon {
  padding-left: 8px !important;
  padding-right: 10px !important;
}
.button--v2--text {
  padding-right: 6px !important;
  padding-left: 6px !important;
  background-color: #fff;
}
.button--v2--text.button--slim {
  padding: 0px 4px !important;
}
.button--block {
  width: 100%;
  display: block;
}
.button--variant-text {
  background-color: transparent;
}
.button--color-primary {
  background-color: #1890ff;
  color: #fff;
  &:hover {
    background-color: #329cff;
  }
  &.button--variant-outlined {
    border: 1px solid #1890ff;
    color: #1890ff;
    background-color: transparent;
    &:hover {
      background-color: #329cff11;
    }
  }
}
.button--color-danger {
  background-color: #ff3333;
  color: #fff;
}
.button--color-secondary {
  background-color: #ff8a00;
  background: linear-gradient(135deg, #ffc700 -3.03%, #ff8a00 103.03%);
  color: #000 !important;
}
.button__icon {
  display: inline-block;
  margin-right: 6px;
}
.button__icon > svg {
  vertical-align: top;
}
.button__soloIcon {
  display: inline-block;
  vertical-align: middle;
}
.button--size-small {
  height: 24px;
  font-size: 14px;
  line-height: 14px;
  padding: 4px;
  min-width: 24px;
}
.button:disabled {
  opacity: 0.5;
  cursor: default;
  color: inherit;
  background-color: #dedede;
}

:global(.fs-dark) {
  .button,
  .button:disabled {
    background-color: #555;
    color: rgba(255, 255, 255, 0.85);
  }
  .button--variant-text {
    background-color: transparent;
  }
  .button--color-primary {
    background-color: #1890ff;
    color: #fff;
  }
  .button--color-danger {
    background-color: #aa0000;
    color: #fff;
  }
  .button--v2 {
    background-color: #292929;
    &.button--color-primary {
      background-color: #1890ff;
      &.button--variant-outlined {
        border: 1px solid #1890ff;
        color: #1890ff;
        background-color: transparent;
        &:hover {
          background-color: #329cff44;
        }
      }
    }
    &.button--variant-outlined {
      border: 1px solid rgba(255, 255, 255, 0.25);
      background-color: #111;
    }
    &.button--color-white {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  .button--v2:hover {
    background-color: #292929;
    &.button--color-primary {
      background-color: #1890ff99;
    }
    &.button--color-white {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  .button--variant-text {
    background-color: transparent;
  }
}
