.app {
  background: transparent;
}
.questBtn {
  background: rgba(49, 133, 252, 0.27);
  border: none;
  border-width: 0;
  margin: 0;
  padding: 0;
  outline: none;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #3185fc;
  -webkit-appearance: none;
  appearance: none;
}
:global(.fs-dark) {
  .questBtn {
    background: rgba(49, 133, 252, 0.27);
  }
}
:global(.fs-nav-sidebar) {
  .questBtn {
    border-radius: 4px;
    font-size: 18px;
    text-align: left;
  }
}
