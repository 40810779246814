.modal {
  & :global(.ant-modal-content) {
    padding: 0 !important;
  }
}
.searchInput {
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 16px;
  background: transparent;
  border-radius: 8px 8px 0 0;
  font-size: 17px;
  outline: none;
  border-bottom: 1px solid #aaa;
  color: currentColor;
}
a.searchResult {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  color: rgba(0, 0, 0, 0.75);
  &:hover, &:focus {
    color: rgba(0, 0, 0, 0.9);
    background: rgba(0, 0, 0, 0.05)
  }
}

:global(.fs-dark) {
  .searchInput {
    border-color: #444;
  }
  a.searchResult {
    color: rgba(255, 255, 255, 0.75);
    &:hover, &:focus {
      color: rgba(255, 255, 255, 0.9);
      background: rgba(255, 255, 255, 0.1)
    }
  }
}
