.mobnav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: calc(50px + env(safe-area-inset-bottom, 20px));
  background: #fff;
  border-top: 1px solid #dedede;
  flex-shrink: 0;
  padding-bottom: env(safe-area-inset-bottom, 20px);
}
a.tab, .tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 2px;
  &:hover,
  &:active {
    color: rgba(0, 0, 0, 0.75);
  }
}
a.tabActive {
  color: rgba(0, 0, 0, 0.85);
  &:hover,
  &:active {
    color: rgba(0, 0, 0, 0.85);
  }
}
.tabIcon {
  padding-top: 10px;
  font-size: 18px;
  line-height: 18px;
}
.tabIcon span {
  vertical-align: top;
}
.tabLabel {
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 0.5em;
}
.mobnavNotLoggedIn {
  min-height: calc(50px + env(safe-area-inset-bottom, 20px));
  background: #fff;
  border-top: 1px solid #aaa;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  flex-shrink: 0;
}
.sidebar {
  width: 240px;
  box-shadow: rgb(0 0 0 / 5%) -1px 0px 0px 0px inset;
  padding: 4px;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.sidebarLink, a.sidebarLink {
  display: block;
  padding: 4px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 1px;
  display: flex;
  cursor: pointer;
  &:hover,
  &:active {
    color: rgba(0, 0, 0, 0.75);
    background: rgba(0, 0, 0, 0.05);
  }
}
a.sidebarLinkActive {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.1);
  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.15);
  }
}
.sidebarLinkIcon {
  height: 22px;
  width: 22px;
  padding: 1px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 20px;
  margin-right: 4px;
  text-align: center;
}
.sidebarLinkText {
  line-height: 20px;
}
:global(.fs-dark) {
  .mobnav,
  .mobnavNotLoggedIn {
    background: #232323;
    border-top: 1px solid #444;
  }
  a.tab, .tab {
    color: rgba(255, 255, 255, 0.5);
    &:hover,
    &:active {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  a.tabActive {
    color: rgba(255, 255, 255, 0.85);
    &:hover,
    &:active {
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .sidebarLink, a.sidebarLink {
    color: rgba(255, 255, 255, 0.7);
    &:hover,
    &:active {
      color: rgba(255, 255, 255, 0.75);
      background: rgba(255, 255, 255, 0.05);
    }
  }
  .sidebarLinkActive, a.sidebarLinkActive {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.1);
    &:hover,
    &:active {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}
